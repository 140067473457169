body {
  background-color: #ebeff5;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
}
.outerMargin {
  margin: 2%;
  max-width: 90%;
}
.ssNavLink {
  font-size: 25px;
}
#confidential {
  background-color: #d9534f;
}
#logo {
  max-width: 60%;
  margin-top: -10%;
  margin-bottom: -10%;
  overflow: hidden;
}
#dashboardContainer {
  margin-left: 5%;
  margin-right: 5%;
  height: 50%;
}
#uploadView {
  height: 100vh;
}
#my-account {
  background-color: #000b76;
}
#total-tickets {
  background-color: #000b76;
}
#total-requests {
  background-color: #000b76;
}
#active-tickets {
  background-color: #000b76;
  margin:0
}
#active-requests {
  background-color: #000b76;
}
#applicant-form {
  max-width: 55%;
}
#secureShareNav {
  background-color: #cecece;
}
#secureShareFooter {
  background-color: #cecece;
  padding: .5%;
}
#secureShareNavLinks {
  text-decoration: none;
}
#navItems {
  text-decoration: none;
  color: black;
  font-family: "Bebas+Neue", sans-serif;
  font-weight: 550;
  font-size: 20px;
  min-width: 40%;
}
#myAccount {
  max-width: 80%;
}
#accountCard {
  padding-bottom: 0;
  
}
#totalTickets {
  min-width: 170px;
  max-width: 80%;
}
#totalRequests {
  min-width: 189px;
  max-width: 80%;
}
#acctAppForm {
  max-width: 70%;
}
#copyRow {
  width: 10%;
}
#copyIcon {
  max-width: 18%;
  margin-left: 5%;
  overflow: hidden;
}

.table tbody tr:hover td, .table tbody tr:hover {
  background-color: #f2f2f2;
}
@media only screen and (max-width: 1080px) {
  #secureShareNav {
    font-weight: 700;
    font-size: 30px;
  }
}

@media only screen and (max-width: 750px) {
  #secureShareNav {
    font-weight: 700;
    font-size: 20px;
  }
}
